// @flow
import { base64Encode } from '@finect/front-utils/base64';
import ACTIONS from '../constants';
import type { StoreType } from './types';

/**
 * Codifica el body en base64 para que no haya problemas con caracteres especiales
 */
const contentModelize = (model: Object) =>
  (model.type === 'article'
    ? {
      ...model,
      body: {
        ...model.body,
        text: base64Encode(model.body.text)
      }
    }
    : model);

export function content(
  state: StoreType = {
    tags: [],
    tag: {
      model: null,
      fetching: false,
      fetched: false,
      contents: {
        items: [],
        fetching: false,
        fetched: false,
        paging: {
          links: [],
          totalItems: 0,
          limit: 10
        }
      },
      latestContents: {
        items: [],
        fetching: false,
        fetched: false
      },
      profiles: {
        items: [],
        fetching: false,
        fetched: false
      }
    },
    tagArticles: {},
    hash: '',

    article: {
      model: {},
      fetching: false,
      fetched: false,
      request: {},
      notFound: false
    },
    articleComments: {
      items: [],
      paging: {
        links: [],
        totalItems: 0,
        limit: 5
      },
      request: {},
      fetching: false,
      fetched: false
    },
    recommendedArticles: {
      items: [],
      fetched: false,
      fetching: false
    },
    relatedQuestions: {
      items: [],
      fetched: false,
      fetching: false
    },
    relatedProducts: {
      items: [],
      fetched: false,
      fetching: false
    },
    // todo: revisar
    relatedGroupArticles: {
      items: [],
      fetched: false,
      fetching: false
    },

    groups: {
      items: [],
      paging: {
        links: [],
        totalItems: 0,
        limit: 24
      },
      request: {
        filters: {
          order: null
        }
      },
      fetching: false,
      fetched: false
    },
    enterpriseAdvisers: {
      items: [],
      fetching: false,
      fetched: false
    },
    videos: {
      items: [],
      paging: {
        links: [],
        totalItems: 0,
        limit: 12
      },
      request: {
        filters: {
          filter: null
        }
      },
      fetching: false,
      fetched: false
    },
    homeVideos: {
      items: [],
      paging: {
        links: [],
        totalItems: 0,
        limit: 12
      },
      fetching: false,
      fetched: false
    },
    homePodcasts: {
      items: [],
      paging: {
        links: [],
        totalItems: 0,
        limit: 12
      },
      fetching: false,
      fetched: false
    },
    // todo: revisar
    banks: {
      items: [],
      fetching: false,
      fetched: false
    },
    questions: {},
    reviews: {}
  },
  action: Object
) {
  switch (action.type) {
    case ACTIONS.SET_TAG_FETCHING_STATUS: {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        ...{
          fetching: action.fetching,
          fetched: action.fetched
        }
      };
      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG: {
      const newState = { ...state };

      if (!action.tag) {
        newState.tag = {
          ...state.tag,
          model: action.tag,
          fetching: false,
          fetched: true
        };

        return { ...state, ...newState };
      }

      newState.tag = {
        ...state.tag,
        model: action.tag,
        fetching: false,
        fetched: true
      };

      const NEW_TAG = {
        model: action.tag,
        videos: {
          items: [],
          paging: {
            links: [],
            totalItems: 0,
            limit: 24
          },
          request: {},
          fetching: false,
          fetched: true
        }
      };

      const tag = newState.tags.find(item => item.model.id === action.tag.id);

      const newTag = tag
        ? {
          ...tag,
          ...{
            model: action.tag
          }
        }
        : NEW_TAG;

      newState.tags = [...newState.tags.filter(item => item.model.id !== action.tag.id), newTag];

      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG_ENTITIES_FETCHING_STATUS: {
      const newState = { ...state };
      const tagIndex = newState.tags.findIndex(item => item.model.id === action.entityId);

      newState.tags[tagIndex][action.contentType].fetching = action.fetching;
      newState.tags[tagIndex][action.contentType].fetched = action.fetched;
      newState.hash += '-fetching';

      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG_VIDEOS: {
      const newState = { ...state };
      const tagIndex = newState.tags.findIndex(item => item.model.id === action.tagId);
      const newTag = { ...newState.tags[tagIndex] };

      newTag.videos = {
        ...newTag.videos,
        ...{
          items: [...action.videos],
          fetching: false,
          fetched: true
        }
      };
      newState.tags[tagIndex] = { ...newTag };
      newState.hash = newState.tags[tagIndex].videos.items.map(item => item.id).join(',');

      return { ...state, ...newState };
    }

    case 'SET_TAGS': {
      const newState = { ...state };
      newState.tags = [...action.tags];
      return { ...state, ...newState };
    }

    case 'CLEAR_TAG': {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        model: null,
        fetching: false,
        fetched: false
      };
      return { ...state, ...newState };
    }

    case 'SET_ARTICLE': {
      const newState = { ...state };
      newState.article.model = {
        ...action.article,
        ...{
          body: {
            ...action.article.body,
            text: base64Encode(action.article.body.text)
          },
          metadata: { ...action.article.body.metadata },
          url: action.article.body.url
        }
      };
      newState.article.fetching = false;
      newState.article.fetched = true;
      newState.article.notFound = false;

      return { ...state, ...newState };
    }

    case 'CLEAR_ARTICLE': {
      const newState = { ...state };
      newState.article.model = {};
      newState.article.fetching = false;
      newState.article.fetched = false;
      newState.article.notFound = false;

      newState.articleComments = {
        items: [],
        paging: {
          links: [],
          totalItems: 0,
          limit: 5
        },
        request: {},
        fetching: false,
        fetched: false
      };

      return { ...state, ...newState };
    }

    case 'SET_ARTICLE_NOT_FOUND': {
      const newState = { ...state };

      newState.article.fetching = false;
      newState.article.fetched = true;
      newState.article.notFound = true;

      return { ...state, ...newState };
    }

    case 'SET_ARTICLE_COMMENTS': {
      const newState = { ...state };
      const oldComments = [...state.articleComments.items];

      newState.articleComments.items = [...action.comments, ...oldComments];
      newState.articleComments.paging = action.paging;
      newState.articleComments.fetching = false;
      newState.articleComments.fetched = true;

      return { ...state, ...newState };
    }

    case 'SET_ARTICLE_USER_COMMENT': {
      const newState = { ...state };
      const oldComments = [...state.articleComments.items];

      newState.articleComments.items = [...oldComments, action.comment];
      newState.articleComments.paging.totalItems += 1;
      newState.article.model.stats.comments += 1;

      return { ...state, ...newState };
    }

    case 'SET_ARTICLE_COMMENTS_FETCHING_STATUS': {
      const newState = { ...state };

      newState.articleComments.fetching = action.fetching;
      newState.articleComments.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_RECOMMENDED_ARTICLES': {
      return {
        ...state,
        recommendedArticles: {
          items: action.articles,
          fetching: false,
          fetched: true
        }
      };
    }

    case 'SET_RECOMMENDED_ARTICLES_FETCHING_STATUS': {
      return {
        ...state,
        recommendedArticles: {
          items: state.recommendedArticles.items,
          fetching: action.fetching,
          fetched: action.fetched
        }
      };
    }

    case 'SET_RELATED_QUESTIONS': {
      return {
        ...state,
        relatedQuestions: {
          items: action.questions,
          fetching: false,
          fetched: true
        }
      };
    }

    case 'SET_RELATED_QUESTIONS_FETCHING_STATUS': {
      return {
        ...state,
        relatedQuestions: {
          items: state.relatedQuestions.items,
          fetching: action.fetching,
          fetched: action.fetched
        }
      };
    }

    case 'SET_RELATED_PRODUCTS': {
      const newState = { ...state };
      newState.relatedProducts.items = action.products;
      newState.relatedProducts.fetching = false;
      newState.relatedProducts.fetched = true;

      return { ...state, ...newState };
    }

    case 'SET_RELATED_PRODUCTS_FETCHING_STATUS': {
      const newState = { ...state };

      newState.relatedProducts.fetching = action.fetching;
      newState.relatedProducts.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_RELATED_GROUP_ARTICLES': {
      return {
        ...state,
        relatedGroupArticles: {
          items: action.articles,
          fetching: false,
          fetched: true
        }
      };
    }

    case 'SET_RELATED_GROUP_ARTICLES_FETCHING_STATUS': {
      return {
        ...state,
        relatedGroupArticles: {
          items: state.relatedGroupArticles.items,
          fetching: action.fetching,
          fetched: action.fetched
        }
      };
    }

    case 'SET_GROUPS': {
      const newState = { ...state };

      const oldGroups = [...state.groups.items];

      const oldGroupsIds = oldGroups.map(item => item.id);

      newState.groups.items = [
        ...oldGroups,
        ...action.groups
          .filter(group => !oldGroupsIds.includes(group.id))
          .map(item => ({
            ...item,
            ...{ disclaimers: [] }
          }))
      ];
      newState.groups.paging = action.paging;
      newState.groups.fetching = false;
      newState.groups.fetched = true;

      return { ...state, ...newState };
    }

    case 'CLEAR_GROUPS': {
      const newState = { ...state };

      newState.groups.items = [];
      newState.groups.paging = null;
      newState.groups.fetching = false;
      newState.groups.fetched = false;
      return { ...state, ...newState };
    }

    case 'SET_GROUPS_FETCHING_STATUS': {
      const newState = { ...state };

      newState.groups.fetching = action.fetching;
      newState.groups.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_GROUPS_WITH_FILTERS': {
      const newState = { ...state };

      newState.groups.items = [...action.groups];
      newState.groups.paging = action.paging;
      newState.groups.fetching = false;
      newState.groups.fetched = true;

      return { ...state, ...newState };
    }

    case 'SET_GROUPS_FILTERS': {
      const newState = { ...state };
      newState.groups.request.filters.order = action.filters;
      newState.groups.fetching = true;
      return { ...state, ...newState };
    }

    case 'SET_ENTERPRISE_ADVISERS': {
      return {
        ...state,
        enterpriseAdvisers: {
          items: action.enterprises,
          fetching: false,
          fetched: true
        }
      };
    }

    case 'SET_VIDEOS': {
      return {
        ...state,
        videos: {
          ...state.videos,
          paging: action.paging,
          items: action.replace ? [...action.videos] : [...state.videos.items, ...action.videos],
          fetching: false,
          fetched: true
        }
      };
    }

    case 'CLEAR_VIDEOS': {
      const newState = { ...state };

      newState.videos = {
        items: [],
        paging: {
          links: [],
          totalItems: 0,
          limit: 12
        },
        request: {
          filters: {
            filter: null
          }
        },
        fetching: false,
        fetched: false
      };
      return { ...state, ...newState };
    }

    case 'SET_VIDEOS_FETCHING_STATUS': {
      const newState = { ...state };

      newState.videos.fetched = action.fetched;
      newState.videos.fetching = action.fetching;

      return { ...state, ...newState };
    }

    case 'SET_VIDEOS_WITH_FILTERS': {
      const newState = { ...state };

      newState.videos.paging = action.paging;
      newState.videos.items = [...action.videos];
      newState.videos.fetching = false;
      newState.videos.fetched = true;

      return { ...state, ...newState };
    }

    case 'SET_VIDEOS_FILTERS': {
      const newState = { ...state };
      newState.videos.fetching = true;
      newState.videos.request.filters.filter = action.filters;

      return { ...state, ...newState };
    }

    case 'SET_HOME_VIDEOS': {
      return {
        ...state,
        homeVideos: {
          ...state.homeVideos,
          paging: action.paging,
          items: action.replace ? [...action.homeVideos] : [...state.homeVideos.items, ...action.homeVideos],
          fetching: false,
          fetched: true
        }
      };
    }

    case 'SET_HOME_VIDEOS_FETCHING_STATUS': {
      const newState = { ...state };

      newState.homeVideos.fetched = action.fetched;
      newState.homeVideos.fetching = action.fetching;

      return { ...state, ...newState };
    }

    case 'SET_HOME_PODCASTS': {
      return {
        ...state,
        homePodcasts: {
          ...state.homePodcasts,
          paging: action.paging,
          items: action.replace ? [...action.homePodcasts] : [...state.homePodcasts.items, ...action.homePodcasts],
          fetching: false,
          fetched: true
        }
      };
    }

    case 'SET_HOME_PODCASTS_FETCHING_STATUS': {
      const newState = { ...state };

      newState.homePodcasts.fetched = action.fetched;
      newState.homePodcasts.fetching = action.fetching;

      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG_CONTENTS: {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        contents: {
          items: [...action.add ? state.tag.contents.items : [], ...action.contents.map(contentModelize)],
          fetching: false,
          fetched: true,
          paging: action.paging
        }
      };

      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG_CONTENTS_FETCHING_STATUS: {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        contents: {
          ...state.tag.contents,
          fetching: action.fetching
        }
      };
      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG_LATEST_CONTENTS: {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        latestContents: {
          items: [...action.contents.map(contentModelize)],
          fetching: false,
          fetched: true
        }
      };
      return { ...state, ...newState };
    }

    case ACTIONS.SET_TAG_LATEST_CONTENTS_FETCHING_STATUS: {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        latestContents: {
          ...state.tag.latestContents,
          fetching: action.fetching
        }
      };
      return { ...state, ...newState };
    }

    case 'SET_ADVISERS_PROFILES': {
      const newState = { ...state };
      newState.tag = {
        ...state.tag,
        profiles: {
          items: action.profiles,
          fetching: false,
          fetched: true
        }
      };
      return { ...state, ...newState };
    }

    // Questions
    case 'SET_QUESTIONS': {
      const newState = { ...state };

      if (!newState.questions[action.id]) {
        newState.questions[action.id] = {
          items: [],
          paging: {},
          request: {},
          fetching: false,
          fetched: false
        };
      }

      const data = { ...newState.questions[action.id] };

      data.items = action.request.offset ? [...data.items, ...action.items] : [...action.items];

      data.paging = action.paging;
      data.fetching = false;
      data.fetched = true;

      const newObject = {
        [action.id]: data
      };

      newState.questions = { ...newState.questions, ...newObject };
      return { ...state, ...newState };
    }

    // Reviews
    case 'SET_REVIEWS_FETCHING_STATUS': {
      const newState = { ...state };

      if (!newState.reviews[action.id]) {
        newState.reviews[action.id] = {
          items: [],
          paging: {},
          request: {},
          fetching: false,
          fetched: false
        };
      }

      const data = {
        ...newState.reviews[action.id],
        ...{
          fetching: action.fetching,
          fetched: action.fetched
        }
      };

      const newObject = {
        ...{
          [action.id]: { ...data }
        }
      };

      return {
        ...state,
        ...{
          reviews: {
            ...newState.reviews,
            ...newObject
          }
        }
      };
    }

    case 'SET_REVIEWS': {
      const newState = { ...state };

      if (!newState.reviews[action.id]) {
        newState.reviews[action.id] = {
          items: [],
          paging: {},
          request: {},
          fetching: false,
          fetched: false
        };
      }

      const data = { ...newState.reviews[action.id] };

      const newItems = action.request.offset ? [...data.items, ...action.items] : [...action.items];

      const newReview = {
        ...{
          fetching: false,
          fetched: true
        },
        ...{
          items: [...newItems],
          paging: action.paging,
          request: action.request
        }
      };

      const newReviews = {
        reviews: {
          ...newState.reviews,
          ...{
            [action.id]: newReview
          }
        }
      };

      return { ...state, ...newReviews };
    }

    case 'SET_ARTICLES_TAG_FETCHING_STATUS': {
      const newState = { ...state };

      if (!newState.tagArticles[action.id]) {
        newState.tagArticles[action.id] = {
          items: [],
          paging: {},
          fetching: action.fetching,
          fetched: false
        };
      }

      const data = { ...newState.tagArticles[action.id] };
      data.fetching = action.fetching;

      const newObject = {
        [action.id]: data
      };

      newState.tagArticles = { ...newState.tagArticles, ...newObject };
      return { ...state, ...newState };
    }

    case 'SET_ARTICLES_TAG': {
      const newState = { ...state };

      if (!newState.tagArticles[action.id]) {
        newState.tagArticles[action.id] = {
          items: [],
          paging: {},
          fetching: false,
          fetched: false
        };
      }

      const data = { ...newState.tagArticles[action.id] };
      data.items = [...data.items, ...action.contents];
      data.paging = action.paging;
      data.fetching = false;
      data.fetched = true;

      const newObject = {
        [action.id]: data
      };
      newState.tagArticles = { ...newState.tagArticles, ...newObject };

      return { ...state, ...newState };
    }

    case 'SET_BANKS_FETCHING_STATUS': {
      const newState = { ...state };
      newState.banks = {
        items: newState.banks.items,
        fetching: true,
        fetched: false
      };
      return { ...state, ...newState };
    }

    case 'SET_BANKS': {
      const newState = { ...state };
      newState.banks = {
        items: [
          ...action.banks.map(item => ({
            ...item,
            link: `/bancos/${item.alias}`,
            image: `/image/${item.icon}`
          }))
        ],
        fetching: false,
        fetched: true
      };
      return { ...state, ...newState };
    }

    case 'CLEAR_BANKS': {
      const newState = { ...state };
      newState.banks = {
        items: [],
        fetching: false,
        fetched: false
      };
      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
