// @flow
import styled, { css } from 'styled-components';
import { Flex } from '@finect/tabular-core/Flex';
import { maxMediaQueries, minMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';
import { grid } from '@finect/tabular-utils/grid';

export { ArticleContentBody } from '@finect/tabular-components/ArticleContentBody';

export const ArticleContent = styled.div`
  width: 100%;
  padding: 0 30px 30px;

  ${maxMediaQueries.phablet`
    padding: 0 24px;
  `};

  ${maxMediaQueries.phone`
    padding: 0 15px;
  `};
`;

export const ArticleHeaderContent = styled.div`
  width: 100%;
  max-width: ${grid(7) + 230 * 2}px;
  margin: 0 auto;

  ${maxMediaQueries.miniDesktop`
    padding-left: 0;
  `};

  ${({ topMargin = false }) =>
    (topMargin ? maxMediaQueries.phablet`padding-top: 24px;` && maxMediaQueries.phone`padding-top: 15px;` : '')};
`;

export const ArticleHeaderInner = styled.div`
  width: 100%;
  max-width: ${grid(6)}px;
  text-align: center;
  margin: auto;

  ${maxMediaQueries.miniDesktop`
    max-width: none;
    margin: 0 auto;
  `}
`;

export const ArticleTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 18px;

  ${minMediaQueries.miniDesktop`
    margin-top: 36px;
    display: inline-block;
  `}
`;

export const ArticleMeta = styled.div`
  display: flex;
  justify-content: center;

  ${({ noBottom = false, theme }) =>
    (noBottom ? '' : `border-bottom: 1px solid ${theme.colors.uiM}; margin-bottom: 30px; padding-bottom: 15px;`)}
  ${getFont(0)}
  color: ${({ theme }) => theme.colors.terciary};

  span {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${maxMediaQueries.miniDesktop`
    margin-bottom: 0;
  `};

  ${maxMediaQueries.phone`
    display: block;
    ${getFont(-2)}
  `};
`;

export const ArticleUserAuthor = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.terciary};

  ${({ noBar = false }) =>
    !noBar &&
    `
  &:after {
    padding: 0 6px;
    content: "–";
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  `}
`;

export const ArticleUserEdit = styled.div`
  font-weight: 600;

  &:before {
    padding: 0 6px;
    content: '–';
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${maxMediaQueries.phone`
      display: none;
    `};
  }
`;

export const ArticleDetail = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: ${grid(7) + 230 * 2}px;
  margin: 0 auto;

  ${maxMediaQueries.miniDesktop`
    display: block;
    > div {
      margin: 0 auto;
    }
  `};
`;

export const ArticleActions = styled.div`
  width: 100%;

  ${minMediaQueries.miniDesktop`
    position: sticky;
    top: 136px;
    flex-shrink: 0;
    width: 230px;
    padding-right: 24px;
    z-index: 2;
  `};
`;

export const ArticleBlock = styled.div`
  width: 100%;
  max-width: ${grid(7)}px;
`;

export const PubliBlock = styled.div`
  width: 100%;
  min-height: 90px;

  ${maxMediaQueries.miniDesktop`
    min-height: 150px;
  `};

  &:first-child {
    margin: 0 auto 30px;
  }

  &:last-child {
    margin: 30px auto 0;
  }

  ${({ withPadding = false }) => (withPadding ? 'padding: 24px 0 32px;' : '')}
  ${({ empty = false }) => (empty ? 'height: 0;' : '')}
`;

export const Publi = styled.div`
  position: relative;
  width: auto;
  height: auto;
  text-align: center;
`;

export const ArticleContentComments = styled.div`
  width: 100%;
  padding: 30px 0 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.uiM};
  ${getFont(1)}

  ${maxMediaQueries.phone`
    ${getFont(0, 'compact')}
  `};

  > div {
    max-width: ${grid(7)}px;
    margin-left: auto;
    margin-right: auto;
  }

  > div > span {
    cursor: pointer;
    ${getFont(0)}

    &:before {
      padding: 0 9px;
      content: '–';
    }

    &:hover {
      color: ${({ theme }) => theme.linkColor};
    }
  }
`;

export const CommentsInner = styled.div`
  width: 100%;
  max-width: ${grid(6)}px;
  margin: 0 auto;
`;

export const NewComment = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const NewCommentInput = styled.div`
  flex: 1;
  width: 100%;

  ${/* sc-sel */ Flex} {
    padding-top: 12px;
  }
`;

export const NewCommentContent = styled.div`
  width: 100%;
  height: ${({ height }) => height + 96};
  border: 1px solid ${({ theme }) => theme.colors.uiM};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
`;

export const NewComentContentImage = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${getFont('-2', 'xcompat')}
  color: ${({ theme }) => theme.colors.terciaryLL};

  img {
    align-self: flex-start;
    max-width: 100%;
    height: auto;
  }

  span {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-shadow: 0 4px 16px 0 rgb(46 46 51 / 0.2);
    margin: 16px;
    cursor: pointer;
    background-color: rgb(175 6 8 / 0.3);

    :hover {
      box-shadow: 0 4px 16px 0 rgb(46 46 51 / 0.2);
    }
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 96px;
  padding: 9px 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  ${getFont(-1, 'compact')}

  &::placeholder {
    color: ${({ theme }) => theme.colors.fontLL};
  }
`;

export const ImageButton = styled.button`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily};
  ${getFont(-2, 'compact')}
  color: ${({ theme }) => theme.linkColor};

  &:hover {
    text-decoration: underline;
  }
`;

export const CommentButton = styled.button`
  min-width: 120px;
  padding: 9px 12px;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-left: 12px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.mainColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${({ theme }) => theme.colors.terciaryLL};
  text-align: center;
  transition: background-color 0.15s;
  ${getFont(-1)}

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryD};
  }

  &:focus {
    box-shadow: 0 0 8px rgb(23 113 251 / 0.5);
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.uiL};
    color: ${({ theme }) => theme.colors.fontLL};
  }
`;

export const Comments = styled.div`
  overflow: hidden;
  max-height: ${({ maxHeight }) => maxHeight}px;
  border-bottom: 1px solid ${({ theme, maxHeight }) => (maxHeight ? theme.colors.uiM : 'transparent')};
  margin-top: 18px;
  margin-bottom: 18px;
  transition: max-height 0.25s ease;
`;

export const NavTitle = styled.div`
  ${getFont(1, 'xcompact')}

  ${maxMediaQueries.phablet`
    ${getFont(0, 'compact')}
  `};

  ${maxMediaQueries.phone`
    ${getFont(-1, 'compact')}
  `};
`;

const defaultStyles = css`
  padding: 12px 24px;
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius} ${theme.borderRadius}`};
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.colors.uiLL};

  ${maxMediaQueries.miniDesktop`
    padding: 15px;
  `};

  ${maxMediaQueries.phone`
    text-align: center;

    > div:last-child {
      text-align: center;
    }
  `};
`;

export const FollowAction = styled.div`
  flex-shrink: 0;
  min-width: 160px;
  text-align: right;
`;

const linedStyles = css`
  width: 100%;
  max-width: ${grid(7)}px;
  padding: 18px;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 0 2px ${({ theme, following }) => (following ? theme.colors.uiM : theme.mainColor)};
  margin-top: 44px;
  margin-bottom: 14px;
  transition: border-color 0.15s ease;

  p:not(:last-child) {
    ${getFont(-2, 'compact')}
  }

  ${maxMediaQueries.miniDesktop`
    max-width: none;
  `};

  ${maxMediaQueries.miniDesktop`
    padding: 15px;

    p {
      display: none;
    }
  `};

  ${maxMediaQueries.phone`
    > div:last-child {
      button {
        width: 100%;
        margin-left: 0;
      }
    }
  `};
`;

export const AuthorFollow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  > div:first-child {
    padding-right: 30px;
  }

  h3 {
    padding-bottom: 3px;
    font-weight: 700;
    ${getFont(1, 'xcompact')}
  }

  > div p {
    color: ${({ theme }) => theme.colors.terciary};
  }

  span,
  span:not(:last-child) {
    max-width: ${grid(5)}px;
    ${getFont(-1, 'compact')}
    color: ${({ theme }) => theme.colors.fontL};
  }

  ${maxMediaQueries.miniDesktop`
    h3 {
      ${getFont(0, 'xcompact')}
    }
  `};

  ${maxMediaQueries.phone`
    display: block;

    > div:first-child {
      padding-right: 0;
      padding-bottom: 15px;
    }

    > div:last-child button {
      min-width: 180px;
    }
  `};

  ${({ lined }) => (lined ? linedStyles : defaultStyles)};
`;

export const FollowCompany = styled.div`
  max-width: ${grid(7) + 230 * 2}px;
  margin: 15px auto 0;

  ${maxMediaQueries.phablet`
    max-width: none;
    padding-left: 0;
  `};
`;

export const ProfessionalNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left;
`;

export const ProfessionalInfo = styled.div`
  display: flex;
  align-items: center;

  strong {
    position: relative;
    padding-right: 15px;
    margin-right: 15px;

    ${maxMediaQueries.miniDesktop`
      display: none;
    `};

    &:after {
      position: absolute;
      top: -3px;
      bottom: -3px;
      right: 0;
      border-right: 1px solid ${({ theme }) => theme.colors.uiM};
      content: '';
    }
  }
`;

export const RelatedProductsContent = styled.div`
  margin: 36px auto;
  ${maxMediaQueries.miniDesktop`
    margin: 24px auto;
  `};
`;

export const RelatedProductItem = styled.div`
  ${maxMediaQueries.miniDesktop`
    margin: 16px auto !important
  `};
`;

export const ArticleSidebarBlock = styled.div`
  width: 100%;

  > div {
    &:not(:first-child) {
      margin-top: 36px;
    }
  }

  ${minMediaQueries.miniDesktop`
    position: sticky;
    top: 136px;
    flex-shrink: 0;
    width: 230px;
    padding-left: 24px;
  `};

  ${maxMediaQueries.miniDesktop`
    padding-left: 24px;
    margin-top: 36px;
  `}

  ${maxMediaQueries.phablet`
    padding-left: 0;
  `}

  ${maxMediaQueries.miniDesktop`
    font-weight: 700;

    > div {
      &:not(:first-child) {
        margin-top: 42px;
      }
    }
  `}
`;

export const ArticleSidebarTitle = styled.h6`
  display: block;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.terciaryD};
  ${getFont(0)}
  text-transform: uppercase;

  &:after {
    display: block;
    position: absolute;
    width: 56px;
    height: 3px;
    border-radius: 6px;
    margin-top: 6px;
    content: '';
    background-color: ${({ theme }) => theme.colors.terciaryD};
  }
`;

export const RelatedContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;

  h4 {
    padding-bottom: 3px;
    color: ${({ theme }) => theme.colors.font};
    ${getFont(-1, 'compact')}

    a {
      color: currentColor;

      &:hover {
        color: ${({ theme }) => theme.mainColor};
      }
    }

    ${maxMediaQueries.phablet`
      ${getFont(-1, 'compact')}
    `};

    ${maxMediaQueries.phablet`
      ${getFont(0, 'compact')}
    `};
  }
`;

const commonStyles = css`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: ${({ size }) => size || '48px'};
  height: ${({ size }) => size || '48px'};
  border-radius: 2px;
  margin: ${({ margin }) => margin || '0 9px 0 0'};

  ${maxMediaQueries.miniDesktop`
    width: 72px;
    height: 72px;
    margin: 0 15px 0 0;
  `};
`;

export const ArticleSidebarSquareImage = styled.div`
  ${commonStyles};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ArticleSidebarSquareIcon = styled.div`
  ${commonStyles};
  background-color: ${({ theme }) => theme.colors.uiLL};
  color: ${({ theme }) => theme.colors.primary};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
